import { FunctionComponent, SVGAttributes } from 'react';

import { ThemeUIStyleObject } from 'voom-gatsby';

import { TrainLocation } from '~templates/train-template';

import { ReactComponent as BeverlyHillsScript } from '~svg/logo/beverly-hills.svg';
import { ReactComponent as NewYorkScript } from '~svg/logo/new-york.svg';
import { ReactComponent as NashvilleScript } from '~svg/logo/nashville.svg';

export interface TrainIconProps {
  icon: TrainLocation;
  sx?: ThemeUIStyleObject;
  className?: string;
}

const iconMapping: Record<
  TrainIconProps['icon'],
  FunctionComponent<SVGAttributes<SVGElement>>
> = {
  BEVERLY_HILLS: BeverlyHillsScript,
  NYC_METRO_AREA: NewYorkScript,
  NASHVILLE: NashvilleScript,
};

export const TrainIcon = ({ icon, sx, className }: TrainIconProps) => {
  const Icon = iconMapping[icon];
  return <Icon sx={{ ...sx }} className={className} />;
};
