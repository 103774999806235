import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Fragment } from 'theme-ui/jsx-runtime';

import {
  Box,
  BreadCrumbs,
  Flex,
  Heading,
  Link,
  Paragraph,
  Section,
  Spacer,
  Text,
} from 'voom-gatsby';

import { TrainDoctor, TrainDoctorProps } from '~components';

export interface TrainHeroProps {
  title: string;
  doctor: TrainDoctorProps['doctor'];
  location: string;
  times: string;
  caption: string[];
  links: {
    url: string;
    label: string;
  }[];
  image: {
    src: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    alt: string;
  };
}

export const TrainHero = ({
  title,
  doctor,
  location,
  times,
  caption,
  links,
  image,
}: TrainHeroProps): JSX.Element => {
  return (
    <Section
      theme="base"
      sx={{
        pt: 8,
        pb: [0, null, null, 8],
        bg: 'transparent',
      }}
    >
      <Flex
        sx={{
          flexDirection: ['column', null, null, 'row'],
          px: 0,
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            alignSelf: 'center',
            pl: [4, null, null, null, 0],
            pr: [4, null, null, 8],
            ml: [null, null, null, 'auto'],
            mb: [7, null, null, 0],
            maxWidth: [650, null, null, null, 800],
          }}
        >
          <BreadCrumbs
            links={[
              { url: '/train-with-us', label: 'Train With Us' },
              {
                url: ``,
                label: location,
              },
            ]}
          />
          <Heading variant="h2">{title}</Heading>
          <Spacer space={4} />
          <TrainDoctor doctor={doctor} />
          <Spacer space={4} />
          <Paragraph
            sx={{ span: { color: 'darkGrey', fontSize: ['md', null, 'lg'] } }}
          >
            <Text>{location}</Text>
            <Text sx={{ mx: [3, 4] }}>•</Text>
            <Text sx={{ display: ['block', 'inline'] }}>{times}</Text>
          </Paragraph>
          <Spacer />
          {caption.map((text) => {
            return (
              <Fragment key={text}>
                <Paragraph sx={{ fontSize: ['xs', 'sm'] }}>{text}</Paragraph>
                <Spacer space={[3, 4]} />
              </Fragment>
            );
          })}
          <Flex
            sx={{
              flexDirection: ['column', 'row'],
              alignItems: ['flex-start', 'center'],
            }}
          >
            {links.map(({ url, label }, index) => {
              return (
                <Link
                  key={label}
                  to={url}
                  variant={index === 0 ? 'button' : 'underline'}
                  sx={{ mr: [0, 4], mb: [4, 0] }}
                >
                  {label}
                </Link>
              );
            })}
          </Flex>
        </Flex>
        <Box
          sx={{
            flex: ['0 0 42%', null, null, null, '0 0 42%'],
            position: 'relative',
          }}
        >
          <GatsbyImage
            image={image.src.childImageSharp.gatsbyImageData}
            alt={image.alt}
            sx={{ width: 'full' }}
          />
        </Box>
      </Flex>
    </Section>
  );
};

export const trainHeroFields = graphql`
  fragment trainHeroFields on ContentJsonSections {
    trainHero {
      BEVERLY_HILLS {
        doctor
        title
        location
        times
        caption
        links {
          url
          label
        }
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
          alt
        }
      }
      NYC_METRO_AREA {
        doctor
        title
        location
        times
        caption
        links {
          url
          label
        }
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
          alt
        }
      }
      NASHVILLE {
        doctor
        title
        location
        times
        caption
        links {
          url
          label
        }
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
          alt
        }
      }
    }
  }
`;
