import { graphql } from 'gatsby';

import { Box, SEO } from 'voom-gatsby';

import { TrainCalendar, TrainCalendarProps } from '~sections/TrainCalendar';
import { TrainCarousel, TrainCarouselProps } from '~sections/TrainCarousel';
import { TrainHero, TrainHeroProps } from '~sections/TrainHero';
import { TrainMap, TrainMapProps } from '~sections/TrainMap';
import { TrainThreeUp, TrainThreeUpProps } from '~sections/TrainThreeUp';

export type TrainLocation = 'NYC_METRO_AREA' | 'NASHVILLE' | 'BEVERLY_HILLS';

interface TainTemplateProps {
  data: {
    contentJson: {
      sections: {
        trainHero: Record<TrainLocation, TrainHeroProps>;
        trainThreeUp: TrainThreeUpProps;
        trainCalendar: TrainCalendarProps;
        trainCarousel: TrainCarouselProps;
        trainMap: Record<TrainLocation, TrainMapProps>;
      };
    };
  };
  pageContext: {
    location: TrainLocation;
    SEO: {
      title: string;
      description: string;
    };
  };
}

/**
 * Train Template
 *
 * Data is pulled from the contentJson file `src/content/train-with-us.json` pages array
 * and passed into this template via gatsby-node createPage function.
 *
 * If new sections need to be added, add data to the contentJson file and
 * update the query to consume the new data.
 *  */
const TrainTemplate = ({ data, pageContext }: TainTemplateProps) => {
  const sections = data.contentJson.sections;
  const { location } = pageContext;
  return (
    <>
      {sections.trainHero && <TrainHero {...sections.trainHero[location]} />}
      <Box
        sx={{
          background: (theme) => `${theme.colors?.tealGradient}`,
        }}
      >
        {sections.trainThreeUp && <TrainThreeUp {...sections.trainThreeUp} />}
        {sections.trainCalendar && (
          <TrainCalendar {...sections.trainCalendar} location={location} />
        )}
      </Box>
      <Box
        sx={{
          background: (theme) => `${theme.colors?.tealGradient}`,
        }}
      >
        {sections.trainCarousel && (
          <TrainCarousel {...sections.trainCarousel} />
        )}
      </Box>
      {sections.trainMap && <TrainMap {...sections.trainMap[location]} />}
    </>
  );
};

export const Head = ({ pageContext }: TainTemplateProps) => {
  return (
    <SEO
      title={pageContext.SEO.title}
      description={pageContext.SEO.description}
    />
  );
};

export default TrainTemplate;

export const TRAIN_TEMPLATE_QUERY = graphql`
  query TrainTemplate {
    contentJson(jsonId: { eq: "train-with-us" }) {
      sections {
        ...trainHeroFields
        ...trainThreeUpFields
        ...trainCalendarFields
        ...trainCarouselFields
        ...trainMapFields
      }
    }
  }
`;
