import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { Box, Flex, Link, Section, Spacer, Text } from 'voom-gatsby';

import { TrainIcon, TrainIconProps } from '~components';

export interface TrainMapProps {
  image: {
    src: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    alt: string;
  };
  map: {
    src: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    alt: string;
  };
  icon: TrainIconProps['icon'];
  locationLink: {
    label: string[];
    url: string;
  };
  link: {
    label: string;
    url: string;
  };
}

export const TrainMap = ({
  image,
  map,
  icon,
  locationLink,
  link,
}: TrainMapProps): JSX.Element => {
  const maxSectionHeight = 750;

  return (
    <Section
      id="map"
      theme="blue"
      sx={{
        mt: [6, 8],
      }}
    >
      <GatsbyImage
        image={image.src.childImageSharp.gatsbyImageData}
        alt={image.alt}
        sx={{
          width: 'full',
          aspectRatio: [1, null, 'auto'],
          maxHeight: maxSectionHeight,
        }}
      />
      <Flex
        sx={{
          flexDirection: ['column', null, null, 'row'],
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ flex: '0 0 50%', position: 'relative' }}>
          <GatsbyImage
            image={map.src.childImageSharp.gatsbyImageData}
            alt={map.alt}
            sx={{ width: 'full', maxHeight: maxSectionHeight }}
          />
        </Box>
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flex: '0 0 50%',
            py: 8,
            px: [4, null, 8],
            maxHeight: maxSectionHeight,
          }}
        >
          <TrainIcon
            icon={icon}
            sx={{
              color: 'white',
              height: [70, null, 80, 90],
              maxWidth: 'full',
            }}
          />
          <Spacer space={6} />
          <Box>
            <Link to={locationLink.url} variant="ghost">
              {locationLink.label.map((label, index) => {
                return (
                  <Text
                    key={label}
                    sx={{
                      display: 'block',
                      fontWeight: index === 0 ? 'semibold' : 'regular',
                    }}
                  >
                    {label}
                  </Text>
                );
              })}
            </Link>
            <Spacer />
            <Link to={link.url} variant="button">
              {link.label}
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Section>
  );
};

export const trainMapFields = graphql`
  fragment trainMapFields on ContentJsonSections {
    trainMap {
      BEVERLY_HILLS {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
          alt
        }
        map {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
          alt
        }
        icon
        locationLink {
          label
          url
        }
        link {
          label
          url
        }
      }
      NYC_METRO_AREA {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
          alt
        }
        map {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
          alt
        }
        icon
        locationLink {
          label
          url
        }
        link {
          label
          url
        }
      }
      NASHVILLE {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
          alt
        }
        map {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
          alt
        }
        icon
        locationLink {
          label
          url
        }
        link {
          label
          url
        }
      }
    }
  }
`;
